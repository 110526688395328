var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-alert',{attrs:{"variant":"danger","show":_vm.userLeaveApplicationData === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v("Error fetching Leave Application data")]),_c('div',{staticClass:"alert-body"},[_vm._v(" No leave application found with this application id. Check "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'leave-applications' }}},[_vm._v(" leave application List ")])],1)]),(_vm.userLeaveApplicationData)?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12"}},[_c('leave-application-view-info',{attrs:{"user-data":_vm.userLeaveApplicationData,"custom-fields":_vm.customFields}})],1)],1)]:_vm._e(),(
                (_vm.$can('Zircly Admin') || _vm.isReviewer) &&
                _vm.userLeaveApplicationData !== null
            )?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","xl":"12","lg":"12","md":"12"}},[_c('b-card',{attrs:{"title":_vm.userLeaveApplicationData.is_applied_withdraw
                                ? 'Withdraw Leave Application'
                                : 'Review Leave Application'}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[(_vm.userLeaveApplicationData.withdraw_request)?_c('b-row',[_c('b-col',{staticClass:"font-weight-bold",attrs:{"md":"6"}},[_vm._v(" Withdraw Reason ")]),_c('b-col',{staticClass:"pb-50",attrs:{"md":"3"}},[_vm._v(" "+_vm._s(_vm.userLeaveApplicationData.withdraw_request.withdraw_reason)+" ")])],1):_vm._e(),(_vm.userLeaveApplicationData.withdraw_request)?_c('b-row',[_c('b-col',{staticClass:"font-weight-bold",attrs:{"md":"6"}},[_vm._v(" Withdraw Status ")]),_c('b-col',{class:{
                                        'text-warning': _vm.userLeaveApplicationData.withdraw_request.is_Approved == '0',
                                        'text-danger': _vm.userLeaveApplicationData.withdraw_request.is_Approved == '2',
                                        'text-success': _vm.userLeaveApplicationData.withdraw_request.is_Approved == '1'
                                    },attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.userLeaveApplicationData.withdraw_request.is_Approved == "0" ? "Withdraw Approval Pending" : _vm.userLeaveApplicationData.withdraw_request.is_Approved == "2" ? "Withdraw Rejected" : _vm.userLeaveApplicationData.withdraw_request.is_Approved == "1" ? "Withdraw Approved" : "")+" ")])],1):_vm._e(),(_vm.userLeaveApplicationData.withdraw_request)?_c('b-row',[_c('b-col',{staticClass:"font-weight-bold",attrs:{"md":"6"}},[_vm._v(" Withdraw Applied On ")]),_c('b-col',{staticClass:"pb-50",attrs:{"md":"6"}},[_vm._v(" "+_vm._s(_vm.userLeaveApplicationData.withdraw_request.applied_on)+" ")])],1):_vm._e(),_c('b-form-group',{attrs:{"label":_vm.userLeaveApplicationData.is_applied_withdraw
                                            ? 'Withdraw Reviewer Reason'
                                            : 'Review Reason',"label-for":"reviewer_reason"}},[_c('b-form-textarea',{attrs:{"id":"reviewer_reason","placeholder":"Write Review for this application","rows":"3"},model:{value:(_vm.reviewReason),callback:function ($$v) {_vm.reviewReason=$$v},expression:"reviewReason"}})],1)],1),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',{attrs:{"label":_vm.userLeaveApplicationData.is_applied_withdraw
                                            ? 'Withdraw Application Status'
                                            : 'Review Application Status',"label-for":"leave-status"}},[[(
                                                !_vm.userLeaveApplicationData.is_applied_withdraw &&
                                                (_vm.userLeaveApplicationData.status ==
                                                    '0' ||
                                                    _vm.userLeaveApplicationData.status ==
                                                        '2')
                                            )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
                                                'rgba(255, 255, 255, 0.15)'
                                            ),expression:"\n                                                'rgba(255, 255, 255, 0.15)'\n                                            ",modifiers:{"400":true}}],staticClass:"ml-5 mt-2",attrs:{"variant":"success"},on:{"click":function($event){return _vm.saveReview('Approved')}}},[_vm._v(" Approve ")]):_vm._e(),(
                                                !_vm.userLeaveApplicationData.is_applied_withdraw &&
                                                (_vm.userLeaveApplicationData.status ==
                                                    '0' ||
                                                    _vm.userLeaveApplicationData.status ==
                                                        '1')
                                            )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
                                                'rgba(255, 255, 255, 0.15)'
                                            ),expression:"\n                                                'rgba(255, 255, 255, 0.15)'\n                                            ",modifiers:{"400":true}}],staticClass:"ml-5 mt-2",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.saveReview('Rejected')}}},[_vm._v(" Reject ")]):_vm._e(),(
                                                _vm.userLeaveApplicationData.is_applied_withdraw &&
                                                (_vm.userLeaveApplicationData
                                                    .withdraw_request
                                                    .is_Approved == '0' ||
                                                    _vm.userLeaveApplicationData
                                                        .withdraw_request
                                                        .is_Approved == '2')
                                            )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
                                                'rgba(255, 255, 255, 0.15)'
                                            ),expression:"\n                                                'rgba(255, 255, 255, 0.15)'\n                                            ",modifiers:{"400":true}}],staticClass:"ml-5 mt-2",attrs:{"variant":"success"},on:{"click":function($event){return _vm.saveReview('Approved')}}},[_vm._v(" Withdraw Approve ")]):_vm._e(),(
                                                _vm.userLeaveApplicationData.is_applied_withdraw &&
                                                (_vm.userLeaveApplicationData
                                                    .withdraw_request
                                                    .is_Approved == '0' ||
                                                    _vm.userLeaveApplicationData
                                                        .withdraw_request
                                                        .is_Approved == '1')
                                            )?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:(
                                                'rgba(255, 255, 255, 0.15)'
                                            ),expression:"\n                                                'rgba(255, 255, 255, 0.15)'\n                                            ",modifiers:{"400":true}}],staticClass:"ml-5 mt-2",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.saveReview('Rejected')}}},[_vm._v(" Withdraw Reject ")]):_vm._e()]],2)],1)],1)],1)],1)],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }