<template>
    <div>
        <!-- Alert: No item found -->
        <b-alert
            variant="danger"
            :show="userLeaveApplicationData === undefined"
        >
            <h4 class="alert-heading">Error fetching Leave Application data</h4>
            <div class="alert-body">
                No leave application found with this application id. Check
                <b-link class="alert-link" :to="{ name: 'leave-applications' }">
                    leave application List
                </b-link>
            </div>
        </b-alert>

        <template v-if="userLeaveApplicationData">
            <!-- First Row -->
            <b-row>
                <b-col cols="12" xl="12" lg="12" md="12">
                    <leave-application-view-info
                        :user-data="userLeaveApplicationData"
                        :custom-fields="customFields"
                    />
                </b-col>
            </b-row>
        </template>

        <template
            v-if="
                ($can('Zircly Admin') || isReviewer) &&
                userLeaveApplicationData !== null
            "
        >
            <!-- Second Row -->
            <b-row>
                <b-col cols="12" xl="12" lg="12" md="12">
                    <b-card
                        :title="
                            userLeaveApplicationData.is_applied_withdraw
                                ? 'Withdraw Leave Application'
                                : 'Review Leave Application'
                        "
                    >
                        <b-row>
                            <b-col md="6" >
                                <b-row v-if="userLeaveApplicationData.withdraw_request">
                                <b-col md="6" class="font-weight-bold">
                                    Withdraw Reason
                                </b-col>
                                <b-col md="3" class="pb-50"  >
                                    {{ userLeaveApplicationData.withdraw_request.withdraw_reason }}
                                </b-col>
                                </b-row>
                                <b-row v-if="userLeaveApplicationData.withdraw_request">
                                <b-col md="6" class="font-weight-bold">
                                    Withdraw Status
                                </b-col>
                                <b-col 
                                    md="6" 
                                    :class="{
                                        'text-warning': userLeaveApplicationData.withdraw_request.is_Approved == '0',
                                        'text-danger': userLeaveApplicationData.withdraw_request.is_Approved == '2',
                                        'text-success': userLeaveApplicationData.withdraw_request.is_Approved == '1'
                                    }"
                                >
                                    {{
                                    userLeaveApplicationData.withdraw_request.is_Approved == "0" ? 
                                    "Withdraw Approval Pending" : 
                                    userLeaveApplicationData.withdraw_request.is_Approved == "2" ? 
                                    "Withdraw Rejected" : 
                                    userLeaveApplicationData.withdraw_request.is_Approved == "1" ? 
                                    "Withdraw Approved" : 
                                    "" 
                                    }}
                                </b-col>

                                </b-row>
                                <b-row v-if="userLeaveApplicationData.withdraw_request">
                                <b-col md="6" class="font-weight-bold">
                                    Withdraw Applied On
                                </b-col>
                                <b-col md="6" class="pb-50">
                                    {{  userLeaveApplicationData.withdraw_request.applied_on}}
                                </b-col>
                                </b-row>
                                <b-form-group
                                    :label="
                                        userLeaveApplicationData.is_applied_withdraw
                                            ? 'Withdraw Reviewer Reason'
                                            : 'Review Reason'
                                    "
                                    label-for="reviewer_reason"
                                >
                                    <b-form-textarea
                                        id="reviewer_reason"
                                        v-model="reviewReason"
                                        placeholder="Write Review for this application"
                                        rows="3"
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group
                                    :label="
                                        userLeaveApplicationData.is_applied_withdraw
                                            ? 'Withdraw Application Status'
                                            : 'Review Application Status'
                                    "
                                    label-for="leave-status"
                                >
                                    <!-- userLeaveApplicationData.is_applied_withdraw ?  -->
                                    <!-- <b-button
                                        class="ml-5 mt-2"
                                        v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                        "
                                        variant="success"
                                        v-if="
                                            userLeaveApplicationData.status ==
                                                '0' ||
                                            userLeaveApplicationData.status ==
                                                '2'
"
                                        @click="saveReview('Approved')"
                                    >
                                        Approve
                                    </b-button>
                                    <b-button
                                        class="ml-5 mt-2"
                                        v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                        "
                                        variant="danger"
                                        v-if="
                                            userLeaveApplicationData.status ==
                                                '0' ||
                                            userLeaveApplicationData.status ==
                                                '1'
                                        "
                                        @click="saveReview('Rejected')"
                                    >
                                        Reject
                                    </b-button> -->
                                    <!-- : -->
                                    <!-- <b-button
                                        class="ml-5 mt-2"
                                        v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                        "
                                        variant="success"
                                        v-if="
                                            userLeaveApplicationData
                                                ?.withdraw_reason
                                                ?.is_Approved == '0' ||
                                            userLeaveApplicationData
                                                ?.withdraw_reason
                                                ?.is_Approved == '2'
                                        "
                                        @click="saveReview('Approved')"
                                    >
                                        Approve
                                    </b-button>
                                    <b-button
                                        class="ml-5 mt-2"
                                        v-ripple.400="
                                            'rgba(255, 255, 255, 0.15)'
                                        "
                                        variant="danger"
                                        v-if="
                                            userLeaveApplicationData
                                                ?.withdraw_reason
                                                ?.is_Approved == '0' ||
                                            userLeaveApplicationData
                                                ?.withdraw_reason
                                                ?.is_Approved == '1'
                                        "
                                        @click="saveReview('Rejected')"
                                    >
                                        Reject
                                    </b-button> -->

                                    <template>
                                        <b-button
                                            class="ml-5 mt-2"
                                            v-ripple.400="
                                                'rgba(255, 255, 255, 0.15)'
                                            "
                                            variant="success"
                                            v-if="
                                                !userLeaveApplicationData.is_applied_withdraw &&
                                                (userLeaveApplicationData.status ==
                                                    '0' ||
                                                    userLeaveApplicationData.status ==
                                                        '2')
                                            "
                                            @click="saveReview('Approved')"
                                        >
                                            Approve
                                        </b-button>
                                        <b-button
                                            class="ml-5 mt-2"
                                            v-ripple.400="
                                                'rgba(255, 255, 255, 0.15)'
                                            "
                                            variant="danger"
                                            v-if="
                                                !userLeaveApplicationData.is_applied_withdraw &&
                                                (userLeaveApplicationData.status ==
                                                    '0' ||
                                                    userLeaveApplicationData.status ==
                                                        '1')
                                            "
                                            @click="saveReview('Rejected')"
                                        >
                                            Reject
                                        </b-button>

                                        <b-button
                                            class="ml-5 mt-2"
                                            v-ripple.400="
                                                'rgba(255, 255, 255, 0.15)'
                                            "
                                            variant="success"
                                            v-if="
                                                userLeaveApplicationData.is_applied_withdraw &&
                                                (userLeaveApplicationData
                                                    .withdraw_request
                                                    .is_Approved == '0' ||
                                                    userLeaveApplicationData
                                                        .withdraw_request
                                                        .is_Approved == '2')
                                            "
                                            @click="saveReview('Approved')"
                                        >
                                            Withdraw Approve
                                        </b-button>
                                        <b-button
                                            class="ml-5 mt-2"
                                            v-ripple.400="
                                                'rgba(255, 255, 255, 0.15)'
                                            "
                                            variant="danger"
                                            v-if="
                                                userLeaveApplicationData.is_applied_withdraw &&
                                                (userLeaveApplicationData
                                                    .withdraw_request
                                                    .is_Approved == '0' ||
                                                    userLeaveApplicationData
                                                        .withdraw_request
                                                        .is_Approved == '1')
                                            "
                                            @click="saveReview('Rejected')"
                                        >
                                            Withdraw Reject
                                        </b-button>
                                    </template>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>
import store from "@/store";
import router from "@/router";
import { ref, onUnmounted } from "@vue/composition-api";
import {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { VueGoodTable } from "vue-good-table";
import leaveApplicationStoreModule from "../leaveApplicationStoreModule";
import LeaveApplicationViewInfo from "./LeaveApplicationViewInfo.vue";
export default {
    components: {
        BRow,
        BCol,
        BAlert,
        BLink,
        BTabs,
        BTab,
        BCard,
        BCardText,
        BDropdown,
        BDropdownItem,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        LeaveApplicationViewInfo,
        VueGoodTable,
    },
    directives: {
        Ripple,
        "b-tooltip": VBTooltip,
    },
    setup() {
        const userLeaveApplicationData = ref(null);
        const leave_application = ref({
            name: "",
            id: "",
        });
        const USER_APP_STORE_MODULE_NAME = "app-leave-applications";

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
            store.registerModule(
                USER_APP_STORE_MODULE_NAME,
                leaveApplicationStoreModule
            );

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME))
                store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        });

        store
            .dispatch(
                "app-leave-applications/fetchLeaveApplication",
                router.currentRoute.params.id
            )
            .then((response) => {
                userLeaveApplicationData.value = response.data.data;
                leave_application.value.name =
                    userLeaveApplicationData.value.name;
                leave_application.value.id =
                    userLeaveApplicationData.value.hashid;
            })
            .catch((error) => {
                if (error.response.status === 404) {
                    userLeaveApplicationData.value = undefined;
                }
            });

        return {
            userLeaveApplicationData,
            leave_application,
        };
    },
    data() {
        const userDataLocal = this.$cookies.get("userData"); // Moved outside of the return statement

        return {
            userDataLocal,
            is_loading: {
                submit: false,
            },
            pageLength: 3,
            leaveApplicationChangeFlag: true,
            pageLengthEmployee: 3,
            dir: false,
            isAddNewInterviewSidebarActive: false,
            searchTerm: "",
            customFields: [],
        };
    },

    created() {
        this.getCustomFields();
    },
    computed: {
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true;
            } else {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = false;
            }
            return this.dir;
        },
        reviewReason: {
            get() {
                if (this.userLeaveApplicationData !== null) {
                    return this.userLeaveApplicationData.is_applied_withdraw
                        ? this.userLeaveApplicationData.withdraw_request
                              ?.withdraw_reviewer_reason
                        : this.userLeaveApplicationData.reviewer_reason;
                }
                return "";
            },
            set(value) {
                if (this.userLeaveApplicationData !== null) {
                    if (this.userLeaveApplicationData.is_applied_withdraw) {
                        this.$set(
                            this.userLeaveApplicationData.withdraw_request,
                            "withdraw_reviewer_reason",
                            value
                        );
                    } else {
                        this.$set(
                            this.userLeaveApplicationData,
                            "reviewer_reason",
                            value
                        );
                    }
                }
            },
        },
        isReviewer() {
            if (this.userLeaveApplicationData !== null) {
                return (
                    this.userLeaveApplicationData &&
                    this.userLeaveApplicationData.reviewer.hashid ===
                        this.userDataLocal.hashid
                );
            }
        },
    },

    methods: {
        viewInterview(id) {
            this.$router.push(`/view/interview/${id}`).catch(() => {});
        },
        assignInterview() {
            console.log("assigning interview...");
            this.isAddNewInterviewSidebarActive = true;
        },
        refetchLeaveApplicationsData() {
            console.log("refetching started..");
        },
        getCustomFields() {
            const self = this;
            self.$store
                .dispatch("app-leave-applications/getCustomFields")
                .then((res) => {
                    self.customFields = res.data.data;
                })
                .catch((error) => {
                    // self.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //         title: "Oops! Custom Fields Getting Failed",
                    //         icon: "BellIcon",
                    //         variant: "danger",
                    //     },
                    // });
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: `Oops! Custom Fields Getting Failed`,
                            icon:'BellIcon',
                            variant : 'danger',
                            hideClose: true,
                            
                        },
                            },
                            {
                        timeout : 3000,
                        position : 'bottom-center',
                        toastClassName:"error-info",
                        hideProgressBar : true,
                            })
                });
        },
        saveReview(status) {
            const self = this;
            const formData = new FormData();
            formData.append(
                "reviewer_reason",
                self.userLeaveApplicationData.reviewer_reason
            );
            formData.append(
                "withdraw_reviewer_reason",
                self.userLeaveApplicationData?.withdraw_request
                    ?.withdraw_reviewer_reason
            );
            formData.append("hashid", self.userLeaveApplicationData.hashid);
            formData.append("status", status);
            formData.append("withdrawStatus", status);
            // self.is_loading.submit = true;
            self.$store
                .dispatch("app-leave-applications/saveReview", formData)
                .then((res) => {
                    if (res.data.success) {
                        // self.is_loading.submit = false;
                        self.$router.go(-1);
                        // self.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: "Review saved successfully",
                        //         icon: "BellIcon",
                        //         variant: "success",
                        //         text: res.data.message,
                        //     },
                        // });
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Review saved successfully',
                                icon: 'BellIcon',
                                variant: 'success',
                                text: res.data.message,
                            },
                            }, {
                            timeout: 2000, 
                            position: 'bottom-center',
                            toastClassName:"radius-8 successful-info",
                            hideProgressBar : true,
                            });
                    } else {
                        // self.is_loading.submit = false;
                        // self.$toast({
                        //     component: ToastificationContent,
                        //     props: {
                        //         title: "Review Update Failed",
                        //         icon: "BellIcon",
                        //         variant: "danger",
                        //         text: res.data.message,
                        //     },
                        // });
                        this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: 'Review Update Failed',
                            icon: 'BellIcon',
                            variant: 'danger',
                            text: res.data.message,
                        },
                        }, {
                        timeout: 2000, 
                        position: 'bottom-center',
                        toastClassName:"radius-8 error-info ",
                        hideProgressBar : true,
                        });
                    }
                })
                .catch((error) => {
                    // self.is_loading.submit = false;
                    // self.$toast({
                    //     component: ToastificationContent,
                    //     props: {
                    //         title: "Oops! Holiday Adding Failed",
                    //         icon: "BellIcon",
                    //         variant: "danger",
                    //         text: error,
                    //     },
                    // });
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Oops! Holiday Adding Failed',
                                icon: 'BellIcon',
                                variant: 'danger',
                                text: error,
                            },
                            }, {
                            timeout: 2000, 
                            position: 'bottom-center',
                            toastClassName:"radius-8 error-info ",
                            hideProgressBar : true,
                            });
                });
        },
        containsKey(obj, key) {
            return Object.keys(obj).includes(key);
        },
    },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>

<style lang="scss" scoped>
.add-btn {
    border: solid 1px;
    width: 70%;
}
.interview-pass {
    color: green;
}
.interview-fail {
    color: indianred;
}
.text-warning{
   color: yellow;
   font-weight: bold;

}
.text-danger{
   color: red;
   font-weight: bold;

}
.text-success{
   color: darkgreen;
   font-weight: bold;
}
</style>
