<template>
  <b-card>
    <b-row>
      <!-- User Info: Left col -->
      <b-col class="d-flex justify-content-between flex-column md-6">
        <b-row class="align-items-center mb-2">
          <b-col cols="12" md="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class=""
              @click="$router.go(-1)"
            >
              <feather-icon icon="ArrowLeftIcon" class="mr-25" />
              <span>List</span>
            </b-button>
          </b-col>
          <b-col cols="12" md="6">
            <h4>Leave Application Details:</h4>
          </b-col>
          <b-col cols="12" md="6" />
        </b-row>
        <b-row>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold">
                Employee Name
              </b-col>
              <b-col md="6" class="pb-50">
                <b-link
                  v-if="$can('employee_show')"
                  :to="{
                    name: 'view-employee',
                    params: { id: userData.user.hashid },
                  }"
                  class="font-weight-bold d-inline-block text-nowrap"
                  v-b-tooltip.hover.v-default
                  :title="userData.user.name"
                >
                  {{ userData.user.name | str_limit(15) }}
                </b-link>
                <span
                  v-else
                  class="d-block text-nowrap"
                  v-b-tooltip.hover.v-default
                  :title="userData.user.name"
                >
                  {{ userData.user.name | str_limit(15) }}
                </span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold">
                Leave Application Type
              </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.leave.type }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Dates </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.dates | formatDatesArray }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Duration </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.duration }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold">
                Application Status
              </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.status_label }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Reviewer </b-col>
              <b-col md="6" class="pb-50">
                <b-link
                  v-if="$can('employee_show')"
                  :to="{
                    name: 'view-employee',
                    params: { id: userData.reviewer.hashid },
                  }"
                  class="font-weight-bold d-inline-block text-nowrap"
                  v-b-tooltip.hover.v-default
                  :title="userData.reviewer.name"
                >
                  {{ userData.reviewer.name | str_limit(15) }}
                </b-link>
                <span
                  v-else
                  class="d-block text-nowrap"
                  v-b-tooltip.hover.v-default
                  :title="userData.reviewer.name"
                >
                  {{ userData.reviewer.name | str_limit(15) }}
                </span>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="6">
            <b-row>
              <b-col md="6" class="pb-50 font-weight-bold"> Applied On </b-col>
              <b-col md="6" class="pb-50">
                {{ userData.applied_on | formatDateTime }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col md="3" class="pb-50 font-weight-bold">
                Applicant Reason
              </b-col>
              <b-col md="9" class="pb-50">
                {{ userData.reason }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col md="3" class="pb-50 font-weight-bold">
                Contact Details
              </b-col>
              <b-col md="9" class="pb-50">
                {{ userData.contact_details }}
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12">
            <b-row>
              <b-col md="3" class="pb-50 font-weight-bold">
                Reviewer Reason
              </b-col>
              <b-col md="9" class="pb-50">
                {{ userData.reviewer_reason }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <b-col v-for="(data, index) in customFields" v-bind:key="index" md="6">
        <b-row>
          <b-col md="6" class="pb-50 font-weight-bold">
            {{ data.label }}
          </b-col>
          <b-col md="6" class="pb-50">
            {{ userData.get_customfields_data["field_" + data.id] }}
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, VBTooltip } from "bootstrap-vue";

import Ripple from "vue-ripple-directive";
import Vue from "vue";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    customFields: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      userDataLocal: this.$cookies.get("userData"),
    };
  },
  methods: {
    openWindow(link) {
      window.open(link);
    },

  },
};
</script>

<style>
</style>
